<template>
	
	<v-col cols="12" sm="12" md="6">
			<v-select
				v-model="selectId"
				:selectId="selectId"
				:items="items"
				label="printSetting"
				@change="change"
			></v-select>
			<v-banner v-model="item" single-line transition="slide-y-transition">
				<v-row>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="left"
						v-model="item.left"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="right"
						v-model="item.right"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="top"
						v-model="item.top"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="bottom"
						v-model="item.bottom"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="QR SIZE"
						v-model="item.qrSize"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="Font size"
						v-model="item.fontSize"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="2" md="2">
						<v-text-field
						label="cols"
						v-model="item.cols"
						></v-text-field>
					</v-col>
				</v-row>
			</v-banner>
		</v-col>
	
</template>

<script>
export default {
	name: "PrintSetting",
	data(){
		return {
			item: this.items[0]
		}
	},
	props:{
		items:Array,
		selectId:Number
	},

	methods:{
		change(){
			let id = this.selectId;
			let items = this.items;

			for(let j = 0;j<items.length;j++) {
				if(id == items[j].id){
					this.item = items[j]
					break;
				}
			}
			this.$emit('selectFunc', this.item);	
		}	
	}
	
}
</script>